import React, { useMemo } from 'react';
import DOMPurify from 'dompurify';

// Function to serialize Slate JSON to HTML
function serializeSlateToHtml(nodes) {

  if (!Array.isArray(nodes) || nodes.length === 0 || !isSlateFormat(nodes)) {
    return nodes; // Return the original value if it's not SlateJS format
  }

  return nodes
    .map((node) => {
      switch (node.type) {
        case 'paragraph':
          return `<p>${serializeSlateToHtml(node.children)}</p>`;
        case 'heading-one':
          return `<h1>${serializeSlateToHtml(node.children)}</h1>`;
        case 'heading-two':
          return `<h2>${serializeSlateToHtml(node.children)}</h2>`;
        case 'bulleted-list':
          return `<ul>${serializeSlateToHtml(node.children)}</ul>`;
        case 'numbered-list':
          return `<ol>${serializeSlateToHtml(node.children)}</ol>`;
        case 'list-item':
          return `<li>${serializeSlateToHtml(node.children)}</li>`;
        case 'block-quote':
          return `<blockquote>${serializeSlateToHtml(node.children)}</blockquote>`;
        case 'code-block':
          return `<pre><code>${serializeSlateToHtml(node.children)}</code></pre>`;
        case 'link':
          return `<a href="${node.url}">${serializeSlateToHtml(node.children)}</a>`;
        case 'image':
          return `<img src="${node.url}" alt="${node.alt || ''}" />`;
        case 'table':
          return `<table>${serializeSlateToHtml(node.children)}</table>`;
        case 'table-row':
          return `<tr>${serializeSlateToHtml(node.children)}</tr>`;
        case 'table-cell':
          return `<td>${serializeSlateToHtml(node.children)}</td>`;
        default:
          if (node.text) {
            let text = node.text;
            if (node.bold) text = `<strong>${text}</strong>`;
            if (node.italic) text = `<em>${text}</em>`;
            if (node.underline) text = `<u>${text}</u>`;
            if (node.strikethrough) text = `<s>${text}</s>`;
            if (node.code) text = `<code>${text}</code>`;
            return text;
          }
          return serializeSlateToHtml(node.children);
      }
    })
    .join('');
  }

// Helper function to determine if the input is in SlateJS format
function isSlateFormat(nodes) {
  return (
    Array.isArray(nodes) &&
    nodes.length > 0 &&
    typeof nodes[0] === 'object' &&
    (nodes[0].hasOwnProperty('type') || nodes[0].hasOwnProperty('text'))
  );
}

// Function to safely parse a JSON string
function tryParseJson(value) {
  try {
    const parsed = JSON.parse(value);
    return parsed;
  } catch (e) {
    return null; // Return null if parsing fails
  }
}

// React component to serialize and render Slate JSON as HTML
const SerializeSlateToHtml = ({ value }) => {

  // Attempt to parse the value as JSON
  const parsedValue = tryParseJson(value);

  // Determine if the parsed value is in SlateJS format or if it should be returned as-is
  const contentToRender = isSlateFormat(parsedValue) ? serializeSlateToHtml(parsedValue) : value;

  // Sanitize the content before rendering to prevent XSS
  const sanitizedHtml = DOMPurify.sanitize(contentToRender);

  return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
};

export default SerializeSlateToHtml;