import React from 'react';
import styles from './formStyling.module.css';
import TimePicker from './TimePicker';

function Product({ enabledProducts, onProductClick, onBackClick, onNextClick, service, step, products, onStartTimeChange, onEndTimeChange }) {

	
	if (typeof service === 'undefined') return '';
	
	const PackagesList = (products.length) ? Object.keys(products).map((key, index) => {
		let product = products[key];

		var image = '/images/' + product.image;
		console.log(enabledProducts);
		return (
			<li key={key} id={product.id} className={`${styles.ListItem} ${(enabledProducts.includes(product.id)) ? styles.selected : ''} `} onClick={() => onProductClick(product.id)}>
				<div>

					<span style={{
						backgroundImage: "url('" + image + "')", height: "190px", width: "250px", backgroundSize: "cover",
						color: "white",
						fontSize: "20px",
						textAlign: "center",
					}}
						className={styles.optionName}>{product.name}</span>

					<span>{product.description}</span>
					
				</div>

			</li>
		);
		
	}) : "No " + service.service + " packages available for the selected event type.";
	
	//const PackagesList = [];
	return (
		<div className={styles.wrap} >
			<h1>{service.service} Step {step} - Choose {service.service}</h1>
			<h3>Please choose a {service.service} that best suits your needs <span className={styles.onlyOne}>(can choose multiple)</span>: </h3>
			
			<hr />
			<ul>

				<li>
					<label>{service.service} Start Time <span className={styles.required}>*</span></label>
					<TimePicker onTimeChange={ onStartTimeChange } />
				</li>
				<li>
					<label>{service.service} End Time <span className={styles.required}>*</span></label>
					<TimePicker onTimeChange={ onEndTimeChange } />
				</li>
				<div className={styles.spacer} />
			</ul>
			<ul className={styles.List}>
				{PackagesList}
			</ul>
			<ul>
				<li>
					<input className={styles.buttondivided} type="submit" value="Next" onClick={onNextClick} />

					<input className={`${styles.buttondivided} ${styles.backButton}`} type="button" value="Go Back" onClick={onBackClick} />
				</li>
			</ul>
		</div>
	);
}

export default Product;
