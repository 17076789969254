import React, { useState } from 'react';
import styles from './formStyling.module.css';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Modal from './Modal';


function Talent({ step, onNextClick, onBackClick, talentOptions, service, handleToggleTalent, selectedTalent }) {

	const [isModalOpen, setModalOpen] = useState(false);
	const [currentVideo, setCurrentVideo] = useState(null);

	const handleImageClick = (event, videoUrl) => {
		event.stopPropagation(); // Prevent the li onClick from firing

		// Add /embed correctly to the YouTube video URL
		const embedUrl = videoUrl.replace("watch?v=", "embed/");

		setCurrentVideo(embedUrl);
		setModalOpen(true);
	};

	const closeModal = () => {
		setModalOpen(false);
		setCurrentVideo(null);
	};

	const TalentList = talentOptions.map((obj, index) => {

		let name = service + "_" + obj.id;

		
		let ratingName = "review_stars_" + obj.starrating;
		let stylesName = styles[ratingName];
		
		var videoSection = "";

		if (obj.video && obj.video.length > 0) {
			videoSection = <img src="/images/play.png" alt="Play Button" class={styles.talentPlayButton} onClick={(event) => handleImageClick(event, obj.video)} />;
		}



		return (


			<div className={(selectedTalent == obj.id) ? styles.selectedTalent : styles.unselectedTalent}>
				<div onClick={() => handleToggleTalent(obj.id)} className={`${styles.accordion} ${styles.accordionExpanded}`}>

					<div className={`${styles.accordionSummary} ${styles.accordionSummaryExpanded}`} 
						
						
						aria-controls={"panel" + index + "-content"}
						id={"panel" + index + "-header"}
					><div><strong>{obj.name}</strong>
							<div class={styles.imageWrapper}>
								<img src={ process.env.REACT_APP_STORAGE_BASE_URL + obj.image} alt={obj.name} className={styles.talentImage} />
								{videoSection}
							</div>
							<span className={`${styles.djreview} ${stylesName}`}></span>
							<div><strong>Estimated Cost:</strong> ${parseFloat(obj.price).toFixed(2)}</div>
						</div>
						<div style={{ margin: "20px" }} className={styles.talentSummary}>
							<div style={{height: "140px"}}>
								{obj.short_description}
							</div>
							<div className={styles.talentButtons}>
								<input className={styles.talentButton} value="View Profile" type="button" onClick={() => { window.location.href = obj.link }} />
							</div>
						</div>
						
	
					</div>

				</div >
			</div>
		);

	});

	return (
		<div className={styles.wrap}  >
			<h1>{service} Step {step} - Choose Talent</h1>
			<h3>Please choose which talent you would like to provide this service</h3>
			<hr />

			<div className={styles.sortByContainer}>
				<label className={styles.sortByLabel}>Sort By:</label>
				<div className={styles.sortByOptions}>
					<label className={styles.sortByOption}>
						<input type="radio" name="sortBy" value="popularity" defaultChecked />
						Popularity
					</label>
					<label className={styles.sortByOption}>
						<input type="radio" name="sortBy" value="price" />
						Price
					</label>
				</div>
			</div>

			{/* Video modal */}
			<Modal isOpen={isModalOpen} onClose={closeModal} videoUrl={currentVideo} />

			<ul>
				{TalentList}
				<li>
					
					<input className={`${styles.buttondivided} ${styles.backButton}`} type="button" value="Go Back" onClick={onBackClick} />
					<input className={styles.buttondivided} type="button" value="Confirm Selected Talent" onClick={onNextClick} />
				</li>
			</ul>
		</div>
	)

}

export default Talent;
