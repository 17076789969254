// src/ThemeContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('default');

  useEffect(() => {
    const hostname = window.location.hostname;
    const searchParams = new URLSearchParams(window.location.search);
    const service = searchParams.get('service');

    if (service === 'djpro') {
        setTheme('djpro');
    } else if (hostname.includes('example1.com')) {
      setTheme('theme1');
    } else if (hostname.includes('example2.com')) {
      setTheme('theme2');
    } else {
      setTheme('default');
    }

}, []);

  return (
    <ThemeContext.Provider value={{ theme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);