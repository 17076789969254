import logo from './logo.png';
import frontImg from './images/front-img-2.jpg';
import './App.css';
import Quoting from './components/Quoting.tsx';
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>DJ Instant Quote</h1>
              <img src={frontImg} alt="front" style={{width:"70%"} } />
      </header>
      <div className="App-body">
        <Router>
            <Quoting />
        </Router>

      </div>
    </div>
  );
}

export default App;
