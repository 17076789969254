import React from 'react'
import SignatureCanvas from 'react-signature-canvas'
import styles from './signature.module.css';

function Contract({ first, last, onNextClick, onBackClick }) {


    let djSignPad = {};
    let boothSignPad = {};
    let photoSignPad = {};

    let clearDj = () => {
        djSignPad.clear();
    }
    let clearBooth = () => {
        boothSignPad.clear();
    }
    let clearPhoto = () => {
        photoSignPad.clear();
    }


    return (
        <>
            <div className={styles.contractContainer}>
                <h1>DJ Booking Contract</h1>
                <p className={styles.contractHeader}>This contract is made between <strong>On The Go DJ Pro</strong> ("DJ") and <strong>{first + last}</strong> ("Client") on this day, <strong>{new Date().toLocaleDateString("en-US", {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                })}</strong>.</p>

                <h2>Terms and Conditions</h2>
                <ol>
                    <li><strong>Event Details:</strong> The DJ agrees to perform at the event on <strong>Tuesday, June 25, 2024</strong> from <strong>12:00 PM</strong> to <strong>5:00 PM</strong>.</li>
                    <li><strong>Payment:</strong> The Client agrees to pay the DJ a total of <strong>$1,445.00</strong>. A deposit of <strong>$500</strong> is due upon signing this contract. The remaining balance is due on the day of the event.</li>
                    <li><strong>Cancellation:</strong> If the Client cancels the event within <strong>5</strong> days of the event date, the deposit is non-refundable.</li>
                    <li><strong>Equipment:</strong> The DJ will provide all necessary equipment for the performance, including sound system, microphones, and music library.</li>
                    <li><strong>Liability:</strong> The DJ is not responsible for any accidents or injuries that occur during the event. The Client agrees to indemnify and hold harmless the DJ from any claims arising from the event.</li>
                    <li><strong>Miscellaneous:</strong> This contract constitutes the entire agreement between the parties and may not be amended except in writing signed by both parties.</li>
                </ol>

                <h2>Signatures</h2>
                <div className={styles.signatureContainer}>
                    <div className={styles.clientSignatureBlock}>
                        <p><SignatureCanvas ref={(ref) => { djSignPad = ref } } penColor='green'
                            canvasProps={{ width: 800, height: 200, className: 'sigCanvas' }} />
                            <div className={styles.signatureLabel} ><strong>Client's Signature</strong> <button className={styles.clearButton} onClick={ clearDj }>Clear Signature</button></div></p>
                    </div>

                </div>

                <p>Date: <strong>{new Date().toLocaleDateString("en-US")}</strong></p>

                <hr />

                <h1>Photo Booth Services Contract</h1>
                <p>This contract is made between <strong>On The Go DJ Pro</strong> ("Provider") and <strong>{first + last}</strong> ("Client") on this day, <strong>{new Date().toLocaleDateString("en-US", {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                })}</strong>.</p>

                <h2>Terms and Conditions</h2>
                <ol>
                    <li><strong>Event Details:</strong> The Provider agrees to set up and manage a photo booth at the event on <strong>Tuesday, June 25, 2024</strong> from <strong>12:00 PM</strong> to <strong>4:00 PM</strong>.</li>
                    <li><strong>Payment:</strong> The Client agrees to pay the Provider a total of <strong>$750.00</strong>. A deposit of <strong>$200</strong> is due upon signing this contract. The remaining balance is due on the day of the event.</li>
                    <li><strong>Cancellation:</strong> If the Client cancels the event within <strong>5</strong> days of the event date, the deposit is non-refundable.</li>
                    <li><strong>Equipment:</strong> The Provider will provide all necessary equipment for the photo booth, including camera, printer, and props.</li>
                    <li><strong>Liability:</strong> The Provider is not responsible for any accidents or injuries that occur during the event. The Client agrees to indemnify and hold harmless the Provider from any claims arising from the event.</li>
                    <li><strong>Miscellaneous:</strong> This contract constitutes the entire agreement between the parties and may not be amended except in writing signed by both parties.</li>
                </ol>

                <h2>Signatures</h2>
                <div className={styles.signatureContainer}>
                    <div className={styles.clientSignatureBlock}>
                        <p><SignatureCanvas penColor='green' ref={(ref) => { boothSignPad = ref }}
                            canvasProps={{ width: 800, height: 200, className: 'sigCanvas' }} />
                            <div className={styles.signatureLabel} ><strong>Client's Signature</strong> <button className={styles.clearButton} onClick={ clearBooth }>Clear Signature</button></div></p>
                    </div>

                </div>

                <p>Date: <strong>{new Date().toLocaleDateString("en-US")}</strong></p>

                <hr />

                <h1>Photography Services Contract</h1>
                <p>This contract is made between <strong>On The Go DJ Pro</strong> ("Photographer") and <strong>{first + last}</strong> ("Client") on this day, <strong>{new Date().toLocaleDateString("en-US", {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                })}</strong>.</p>

                <h2>Terms and Conditions</h2>
                <ol>
                    <li><strong>Event Details:</strong> The Photographer agrees to provide photography services at the event located at on <strong>Tuesday, June 25, 2024</strong> from <strong>3:15 PM</strong> to <strong>12:00 PM</strong>.</li>
                    <li><strong>Payment:</strong> The Client agrees to pay the Photographer a total of <strong>$3,807.50</strong>. A deposit of <strong>$1,000</strong> is due upon signing this contract. The remaining balance is due on the day of the event.</li>
                    <li><strong>Cancellation:</strong> If the Client cancels the event within <strong>5</strong> days of the event date, the deposit is non-refundable.</li>
                    <li><strong>Equipment:</strong> The Photographer will provide all necessary equipment for the photography services, including cameras, lenses, and lighting.</li>
                    <li><strong>Liability:</strong> The Photographer is not responsible for any accidents or injuries that occur during the event. The Client agrees to indemnify and hold harmless the Photographer from any claims arising from the event.</li>
                    <li><strong>Miscellaneous:</strong> This contract constitutes the entire agreement between the parties and may not be amended except in writing signed by both parties.</li>
                </ol>

                <h2>Signatures</h2>
                <div className={styles.signatureContainer}>
                    <div className={styles.clientSignatureBlock}>
                        <p><SignatureCanvas penColor='green' ref={(ref) => { photoSignPad = ref }}
                            canvasProps={{ width: 800, height: 200, className: 'sigCanvas' }} />
                            <div className={styles.signatureLabel} ><strong>Client's Signature</strong> <button className={styles.clearButton} onClick={ clearPhoto }>Clear Signature</button></div></p>
                    </div>
                </div>

                <p>Date: <strong>{new Date().toLocaleDateString("en-US")}</strong></p>


                
                        <input className={styles.buttondivided} type="submit" value="Submit Form" onClick={onNextClick} />

                        <input className={`${styles.buttondivided} ${styles.backButton}`} type="button" value="Go Back" onClick={onBackClick} />
                    

            </div>



        </>
    );
}

export default Contract;
