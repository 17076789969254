import React from 'react';
import styles from './formStyling.module.css';


function TimePicker({ onTimeChange }) {

    let CurrentHour = 12;
    let CurrentMinute = 0;
    let CurrentAmPm = 'PM';

    const sendTimeChange = () => {
        let localHour = CurrentHour;
        if (localHour === 12) localHour = 0; 
        if (CurrentAmPm === 'PM') localHour = parseInt(CurrentHour) + 12;

        onTimeChange(localHour + ':' + CurrentMinute);
    }

    const onHourChange = ({ target }) => {
        CurrentHour = target.value;
        sendTimeChange();       
    }

    const onMinuteChange = ({ target }) => {
        CurrentMinute = target.value;
        sendTimeChange();
    }

    const onAmPM = ({ target }) => {
        CurrentAmPm = target.value;
        sendTimeChange();
    }

    return (
        <>
            <select required defaultValue="12" onChange={onHourChange}>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
                <option>11</option>
                <option>12</option>
            </select>
            <select required onChange={ onMinuteChange }>
                <option>00</option>
                <option>15</option>
                <option>30</option>
                <option>45</option>
            </select>
            <select required defaultValue="PM" onChange={onAmPM }>
                <option>AM</option>
                <option defaultChecked>PM</option>
            </select>
        </>
    );
}

export default TimePicker;