import React from 'react';
import styles from './formStyling.module.css';

// Example modal component, can be replaced with a library component
const Modal = ({ isOpen, onClose, videoUrl }) => {
	if (!isOpen) return null;
	return (
		<div className={styles.modal}>
			<div className={styles.modalContent}>
				<button onClick={onClose} className={styles.closeButton}>Close</button>
				<div className={styles.videoContainer}>
					<iframe width="100%" height="100%"  src={videoUrl} title="Video Player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
				</div>
			</div>
		</div>
	);
};

export default Modal;