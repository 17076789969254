import styles from './payment.module.css';

function Payment() {
        
    return (

        <div  className={styles.paymentContainer}>
            <h1>Payment Page</h1>
            <p>Please pay the total deposit amount of <strong>$1,700</strong> for the DJ Booking, Photo Booth Services, and Photography Services contracts.</p>

            <h2>Payment Options</h2>
            <div className={styles.paymentOption}>
                <h3>Zelle</h3>
                <p>Send payment to: <strong>[Zelle Email/Phone]</strong></p>
            </div>

            <div className={styles.paymentOption}>
                <h3>Credit Card</h3>
                <form>
                    <label for="cardName">Name on Card</label>
                    <input type="text" id="cardName" name="cardName" required />

                    <label for="cardNumber">Card Number</label>
                    <input type="text" id="cardNumber" name="cardNumber" required />

                    <label for="expiryDate">Expiry Date</label>
                    <input type="text" id="expiryDate" name="expiryDate" required placeholder="MM/YY" />

                    <label for="cvv">CVV</label>
                    <input type="text" id="cvv" name="cvv" required />

                    <button type="submit">Pay Now</button>
                </form>
            </div>

            <div className={styles.paymentOption}>
                <h3>Check</h3>
                <p>Make check payable to: <strong>[Your Name/Company]</strong></p>
                <p>Mail to: <strong>[Your Address]</strong></p>
            </div>

            <div className={styles.paymentOption}>
                <h3>PayPal</h3>
                <p>Send payment to: <strong>[PayPal Email]</strong></p>
            </div>

            <div className={styles.paymentOption}>
                <h3>Venmo</h3>
                <p>Send payment to: <strong>@[Venmo Username]</strong></p>
            </div>

            <div className={styles.paymentOption}>
                <h3>Customer Service</h3>
                <p>If you would prefer to pay over the phone, please contact our customer service at <strong>[Customer Service Phone]</strong>.</p>
            </div>
        </div>

    );
}

export default Payment;