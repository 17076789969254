import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeProvider, useTheme } from './ThemeContext';
import './index.css'; // Import your CSS file
import reportWebVitals from './reportWebVitals';

const DynamicHeaderFooter = () => {
  const { theme } = useTheme();
  const [Header, setHeader] = useState(null);
  const [Footer, setFooter] = useState(null);

  useEffect(() => {
    const loadComponents = async () => {
      const { default: LoadedHeader } = await import(`./themes/${theme}/Header`);
      const { default: LoadedFooter } = await import(`./themes/${theme}/Footer`);
      setHeader(() => LoadedHeader);
      setFooter(() => LoadedFooter);
    };
    loadComponents();
  }, [theme]);

  if (!Header || !Footer) return null;

  return (
    <>
      <Header />
      <App />
      <Footer />
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider>
    <DynamicHeaderFooter />
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
